export const environment = {
  production: false,
  b2cConfigs: {
    tenantName: 'lawafshpjobportal',
    clientId: '7dd0cdc5-416d-4795-becf-672a7fdf3973',
    redirectUri: 'https://dev.jobsatlax.org/',
    logoutRedirectUri: '/',
    policyNames: {
      signUpSignIn: 'B2C_1_signup_signin',
      editProfile: 'B2C_1_signup_signin',
      signupJobseeker: 'B2C_1_jobseeker_signup',
      signupEmployer: 'B2C_1_employer_signup',
      resetPassword: 'B2C_1_lawa_pwd_reset',
      signupCBO: 'B2C_1_cbo_signup',
    },
  },
  umbracoBaseUri: 'https://dev-cms.jobsatlax.org',
  apiUrl: 'https://dev-api.jobsatlax.org/api',
  appInsights: {
    instrumentationKey: '018bf616-bb31-4536-9ac1-4f4e16a4e081',
  },
  translationApiKey: '11cf6e43d62640be8af276bafdc09df6',
  translationEndpoint:
    'https://ai-fshp-dev-westus3-001.cognitiveservices.azure.com',
  googleTag: 'G-5Z9TNH8X38',
  powerBiuri:
    'https://app.powerbi.com/groups/me/apps/8be24bc7-d126-4697-b34f-46380bf1d7a9/reports/c5d8f3e3-8ae3-47bb-9523-69057b722512/0a007b0b1075d63f0569?experience=power-bi&clientSideAuth=0',
};
